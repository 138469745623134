import React, {useEffect, useState} from "react"
import {
  FaDollarSign,
  FaEuroSign,
  FaPoundSign,
  FaBitcoin,
  FaEthereum,
} from "react-icons/fa" // React Icons'dan ikonlar
import "./Currentsy.css"

const currencyIcons = {
  USD: <FaDollarSign />,
  EUR: <FaEuroSign />,
  GBP: <FaPoundSign />,
  BTC: <FaBitcoin />,
  ETH: <FaEthereum />,
}

const Currentsy = () => {
  const [tickerItems, setTickerItems] = useState([])

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch(
          "https://api.musmerexchange.com/api/exchangeratestoday/",
          {
            method: "GET",
            headers: {
              Authorization:
                "token 89c68b813f913a67d737d37dbd534a7ee560a2ff8b3e45a63cae1323377433f3",
            },
          }
        )
        const data = await response.json()
        //   console.log("Fetched exchange rates:", data)
        return data
      } catch (error) {
        console.error("Error fetching exchange rates:", error)
        return null
      }
    }

    const populateTicker = async () => {
      const items = []

      const rates = await fetchExchangeRates()
      if (rates) {
        rates.forEach((item) => {
          const {currency__name, selling_price} = item
          items.push({
            symbol: currency__name,
            value: parseFloat(selling_price),
            icon: currencyIcons[currency__name] || <FaDollarSign />, // Varsayılan olarak dolar simgesi
          })
        })
      }

      setTickerItems(items)
    }

    populateTicker()
  }, [])

  return (
    <div className="currency-ticker-wrapper">
      <div className="currency-ticker-logo"></div>
      <div className="currency-ticker">
        <div className="currency-ticker-content">
          {tickerItems.slice(0, 4).map((item, index) => (
            <div key={index} className="currency-ticker-item">
              <div className="currency-icon-wrapper">
                <span className="currency-icon">{item.icon}</span>
              </div>
              <span className="currency-price">{item.value.toFixed(2)} ₺</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Currentsy
