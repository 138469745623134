import React, {useContext} from "react"
import "./Cerez.css" // CSS dosyasını ekleyin
import {RelatedContext} from "../../Context/RelatedContext"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import RelatedComponent from "../../Components/RelatedComponent/RelatedComponent"
import TitleBox from "../../Components/TitleBox/TitleBox"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent"

const Cerez = () => {
  const {Related} = useContext(RelatedContext)
  return (
    <>
      <TopRelatedCategory />
      <div className="Cerez-Container">
        <div className="Cerez-Content">
          <TitleBox title={"Çerez Politikası"} />
          <div className="Cerezler-left-line" />
          <h1 className="Cerez-Title">Çerez Politikası</h1>

          <section className="Cerez-Section">
            <h2 className="Cerez-Subtitle">Biz Kimiz</h2>
            <p className="Cerez-Text">
              Web sitemizin adresi:{" "}
              <a href="https://kibrisligazetesi.com">kibrisligazetesi.com</a>
            </p>
          </section>
          <section className="Cerez-Section">
            <h2 className="Cerez-Subtitle">Yorumlar</h2>
            <p className="Cerez-Text">
              Ziyaretçiler sitede yorum bıraktığında yorum formunda gösterilen
              verileri ve ayrıca istenmeyen yorum tespitine yardımcı olmak için
              ziyaretçinin IP adresi ve tarayıcı bilgisi metnini de toplarız.
            </p>
            <p className="Cerez-Text">
              E-posta adresinizden oluşturulmuş anonimleştirilmiş bir metin
              (hash olarak da adlandırılır) Gravatar hizmetine, servisi kullanıp
              kullanmadığınızı görmek için, sağlanabilir. Gravatar servisinin
              gizlilik politikası şu adrestedir:{" "}
              <a href="https://automattic.com/privacy/">
                Gravatar Privacy Policy
              </a>
              . Yorumunuzun onaylanmasından sonra profil resminiz yorumunuzla
              birlikte herkese görünür.
            </p>
          </section>
          <section className="Cerez-Section">
            <h2 className="Cerez-Subtitle">Ortam</h2>
            <p className="Cerez-Text">
              Görselleri web sitesine yüklerseniz, gömülmüş konum verileri (EXIF
              GPS) içeren görseller yüklemekten kaçınmalısınız. Web sitesi
              ziyaretçileri, web sitesindeki görsellerden herhangi bir konum
              bilgisini indirip çıkarabilir.
            </p>
          </section>
          <section className="Cerez-Section">
            <h2 className="Cerez-Subtitle">Çerezler</h2>
            <p className="Cerez-Text">
              Sitemize bir yorum bırakırsanız, isminizi, e-posta adresinizi ve
              web sitenizi çerezlerde kaydetmeyi seçebilirsiniz. Bunlar size
              kolaylık sağlamak içindir, böylece başka bir yorum bıraktığınızda
              bilgilerinizi tekrar doldurmanız gerekmez. Bu çerezler bir yıl
              süresince kalır.
            </p>
            <p className="Cerez-Text">
              Eğer giriş sayfasımızı ziyaret ederseniz, tarayıcınızın çerezleri
              kabul edip etmediğini belirlemek için geçici bir çerez
              ayarlayacağız. Bu çerez hiçbir kişisel veri içermez ve
              tarayıcınızı kapattığınızda atılır.
            </p>
            <p className="Cerez-Text">
              Giriş yaptığınızda, giriş bilgilerinizi ve ekran görüntüleme
              seçiminizi kaydetmek için birkaç çerez kaydedeceğiz. Giriş
              çerezleri iki gün ve ekran seçenekleri çerezleri bir yıl boyunca
              kalır. “Beni hatırla” seçeneğini seçerseniz, girişiniz iki hafta
              boyunca devam eder. Hesabınızdan çıkış yaparsanız, giriş çerezleri
              kaldırılacaktır.
            </p>
            <p className="Cerez-Text">
              Bir makaleyi düzenler veya yayınlarsanız tarayıcınıza ek bir çerez
              kaydedilir. Bu çerez hiçbir kişisel veri içermez ve sadece
              düzenlediğiniz makalenin yazı kimliğini gösterir. 1 gün sonra
              zaman aşımına uğrar.
            </p>
          </section>
          <section className="Cerez-Section">
            <h2 className="Cerez-Subtitle">Diğer sitelerden gömülen içerik</h2>
            <p className="Cerez-Text">
              Bu sitedeki makaleler gömülü içerik (ör. videolar, görseller,
              makaleler, vb.) içerebilir. Diğer web sitelerinden gömülen içerik,
              ziyaretçinin diğer web sitesini ziyaret etmiş gibi, tam olarak
              aynı şekilde davranır.
            </p>
            <p className="Cerez-Text">
              Bu web siteleri sizin hakkınızda veri toplayabilir, çerez
              kullanabilir, üçüncü taraf tarafından gömülmüş şekilde takip
              yapabilir ve bir hesabınız varsa ve bu web sitesinde oturum
              açtıysanız, gömülen içerikle etkileşiminizi takip etme dahil olmak
              üzere, bu gömülen içerikle etkileşiminizi izleyebilir.
            </p>
          </section>
          <section className="Cerez-Section">
            <h2 className="Cerez-Subtitle">Verinizi kimlerle paylaşıyoruz</h2>
            <p className="Cerez-Text">
              Parola sıfırlama isteğinde bulunduğunuzda, IP adresiniz sıfırlama
              e-postasına eklenir.
            </p>
          </section>
          <section className="Cerez-Section">
            <h2 className="Cerez-Subtitle">
              Verilerinizi ne kadar süre tutarız
            </h2>
            <p className="Cerez-Text">
              Bir yorum bırakırsanız, yorum ve meta verileri süresiz olarak
              saklanır. Böylece, takip eden yorumlarınızı denetim kuyruğunda
              tutmak yerine otomatik olarak tanıyabilir ve onaylayabiliriz.
            </p>
            <p className="Cerez-Text">
              Web sitemize kayıt yaptıran kullanıcılar için (varsa) kullanıcı
              profilinde sağladıkları kişisel bilgileri de saklarız. Tüm
              kullanıcılar kişisel bilgilerini istedikleri zaman görebilir,
              düzenleyebilir veya silebilir (kullanıcı adı değiştirme hariç).
              Web sitesi yöneticileri de bu bilgileri görebilir ve
              düzenleyebilir.
            </p>
          </section>
          <section className="Cerez-Section">
            <h2 className="Cerez-Subtitle">
              Verileriniz üzerindeki haklarınız neler
            </h2>
            <p className="Cerez-Text">
              Bu sitede bir hesabınız veya yorumlarınız varsa, bize sağladığınız
              veriler dahil olmak üzere, hakkınızda tuttuğumuz kişisel verilerin
              dışa aktarılmış bir dosyasını almayı isteyebilirsiniz. Ayrıca,
              hakkınızda tuttuğumuz tüm kişisel verileri de silmeyi
              isteyebilirsiniz. Bu, idari, yasal veya güvenlik amaçlarına uymak
              zorunda olduğumuz hiçbir veriyi içermez.
            </p>
          </section>
          <section className="Cerez-Section">
            <h2 className="Cerez-Subtitle">Verilerinizin gönderildiği yer</h2>
            <p className="Cerez-Text">
              Ziyaretçi yorumları otomatik istenmeyen yorum algılama servisi
              aracılığıyla kontrol edilebilir.
            </p>
          </section>
          <div>
            <SingleRelatedComponent />
          </div>
        </div>

        <div className="cerez-side">
          <Side />
        </div>
      </div>
    </>
  )
}

export default Cerez
