import React, {useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import "./Yazarlarimiz.css"
import {Config} from "../../Config/config"
import TitleBox from "../../Components/TitleBox/TitleBox"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent"
import Loading from "../../Components/Loading/Loading"
import { turkceToEnglish } from "../../Components/PathHelper/PathHelper"
const Yazarlarimiz = () => {
  const [yazarlar, setYazarlar] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchYazarDetails = async () => {
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/reportereditor/?is_reporter=false&is_active=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${Config.Token}`,
            },
          }
        )
        const data = await response.json()
        setYazarlar(data)
      } catch (error) {
        console.error("Hata:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchYazarDetails()
  }, [])

  if (loading) {
    return <Loading />
  }

  const handleRedirect = (id, name) => {
    //  console.log("yazar id", name, id)
   const safeName=turkceToEnglish(name)
    navigate(`/yazarlar/${id}/${safeName}`)
  }
  return (
    <>
      <TopRelatedCategory />
      <div className="Yazarlarımız-page">
        <TitleBox title="Yazarlar" />
        <div className="container">
          <div className="yazarlar-list">
            <h1>Yazarlarımız</h1>
            <div className="yazarlar-cell">
              {yazarlar
                .filter((yazar) => yazar.id !== 12)
                .map((yazar) => (
                  <div key={yazar.id} className="yazar"  onClick={() => handleRedirect(yazar.id, yazar.name)}>
                    <div className="yazar-image-container">
                      <img
                        src={yazar.image || "default-image.jpg"}
                        alt={yazar.name}
                        className="yazar-image"
                      />
                    </div>

                    <h2 className="red-header">{yazar.name}</h2>
                    <p>{yazar.title || ""}</p>
                    <p>{yazar.email}</p>
                    <div className="writers-buttons-wrappers">
                      <button
                        className="btn-blue"
                        onClick={() => handleRedirect(yazar.id, yazar.name)}
                      >
                        Tüm Yazıları
                      </button>
                    </div>
                  </div>
                ))}
            </div>

            <SingleRelatedComponent />
          </div>

          <div className="yazarlar-side">
            <Side />
          </div>
        </div>
      </div>
    </>
  )
}

export default Yazarlarimiz
