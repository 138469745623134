import React, {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import "./Manset.css"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import TitleBox from "../../Components/TitleBox/TitleBox"
import {RelatedContext} from "../../Context/RelatedContext"
import {Config} from "../../Config/config"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import DefaultImage from "../../img/kıbrıslı-gazetesi-haber-default-img-min.jpg"
import Loading from "../../Components/Loading/Loading"
import OzelHaberImage from "../../Components/OzelHaberEtiketi/OzelHaberImage"
import {turkceToEnglish} from "../../Components/PathHelper/PathHelper"
import Pagination from "../../Components/Pagination/Pagination"

const Manset = () => {
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const navigate = useNavigate()
  const {Related} = useContext(RelatedContext)

  // 📌 API'den veri çekme fonksiyonu
  const fetchData = async (page = 1) => {
    setLoading(true)
    setError(null)

    try {
      const response = await fetch(
        `${Config.ApiBaseUrl}/manset/?is_active&page=${page}`,
        {
          method: "GET",
          headers: {Authorization: `Token ${Config.Token}`},
        }
      )
      const data = await response.json()

      if (Array.isArray(data.results)) {
        setArticles(data.results)
        setTotalPages(data.pages || 1) // API'den toplam sayfa sayısını al
      } else {
        setArticles([])
      }
    } catch (err) {
      console.error("Veri çekme hatası:", err)
      setError("Veri alınırken bir hata oluştu.")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(currentPage)
    window.scrollTo(0, 0)
  }, [currentPage])

  // 📌 Sayfa değişim fonksiyonu
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return
    setCurrentPage(pageNumber)
  }

  // 📌 Habere tıklama fonksiyonu
  const handleNewsClick = (post, title) => {
    navigate(`/haber/${post}/${turkceToEnglish(title)}`)
  }

  return (
    <>
      <TopRelatedCategory />
      <div className="category-page-titleBox">
        <TitleBox title="Manşet" />
      </div>

      <div className="category-page">
        <div className="catgeoryAd">
          <div className="articles">
            {loading && <Loading />}
            {error && <p className="error-message">{error}</p>}
            {!loading && articles.length === 0 && (
              <p>Şu anda gösterilecek haber bulunmamaktadır.</p>
            )}

            <div className="articles-grid">
              {articles.map((article) => {
                const coverImage = article.post__image || DefaultImage
                return (
                  <div
                    key={article.post}
                    className="article-card"
                    onClick={() =>
                      handleNewsClick(
                        article.post,
                        article.manset_header ||
                          article.header ||
                          article.post__header
                      )
                    }
                  >
                    <img
                      src={coverImage}
                      alt={
                        article.manset_header ||
                        article.header ||
                        article.post__header
                      }
                      className="article-image"
                    />

                    <OzelHaberImage
                      ReporterImg={
                        article.post__editor === 12
                          ? null
                          : article.reporter_image ||
                            (article.post__is_koseyazisi
                              ? article.editor_image
                              : null)
                      }
                    />

                    <h3 className="article-title">
                      {article.manset_header ||
                        article.header ||
                        article.post__header}
                    </h3>
                  </div>
                )
              })}
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>

        <div className="haber-details-side-wrapper">
          <Side />
        </div>
      </div>
    </>
  )
}

export default Manset
