import React, {useContext} from "react"
import RelatedComponent from "./RelatedComponent"
import {RelatedContext} from "../../Context/RelatedContext"
import "./HaberIciRelatedOrta.css"

export default function HaberIciRelatedOrta() {
  const {Related} = useContext(RelatedContext)

  return (
    <div className="haberIciRelatedorta">
      <div className="haberIciRelatedorta-col">
        <RelatedComponent relatedId={14}  />
        <RelatedComponent relatedId={13}  />
        <RelatedComponent relatedId={12}  />
        <RelatedComponent relatedId={11}  />
      </div>
    </div>
  )
}
