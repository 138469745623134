import React from "react";
import "./Pagination.css";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  if (totalPages <= 1) return null;

  const getPageNumbers = () => {
    let pages = [];

    if (totalPages <= 3) {
      // Eğer toplam sayfa sayısı 3 veya daha az ise tüm sayfaları göster
      pages = [...Array(totalPages)].map((_, index) => index + 1);
    } else {
      // Sayfa ortalamalı şekilde 3 tane sayfa gösterme
      if (currentPage === 1) {
        pages = [1, 2, 3];
      } else if (currentPage === totalPages) {
        pages = [totalPages - 2, totalPages - 1, totalPages];
      } else {
        pages = [currentPage - 1, currentPage, currentPage + 1];
      }
    }

    return pages.filter((page) => page >= 1 && page <= totalPages);
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="pagination-component-wrapper">
      <div className="pagination-container">
        <button
          onClick={() => onPageChange(1)}
          className="page-button-index"
          disabled={currentPage === 1}
        >
          {"<<"}
        </button>
        <button
          onClick={() => onPageChange(currentPage - 1)}
          className="page-button-index"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>

        {pageNumbers.map((page, index) => (
          <button
            key={index}
            onClick={() => onPageChange(page)}
            className={`page-button-index-${
              currentPage === page ? "active" : ""
            }`}
          >
            {page}
          </button>
        ))}

        <button
          onClick={() => onPageChange(currentPage + 1)}
          className="page-button-index"
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>

        <button
          onClick={() => onPageChange(totalPages)}
          className="page-button-index"
          disabled={currentPage === totalPages}
        >
          {">>"}
        </button>
      </div>
    </div>
  );
};

export default Pagination;
