import React, { useEffect, useState } from "react";
import "./OzelHaberEtiketi.css";
import { Config } from "../../Config/config";
import defaultBadgeImage from "../../img/ozelHaberler/özel haberler-3.png";
import kıbrıslıLogo from "../../img/kibrisli-logo.jpg"
const OzelHaberEtiketi = ({
  reporter,
  editor,
  editorDetail,
  showDefaultIfEmpty = false,
  haberDetail = false,
  onUserMail,
}) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `${Config.ApiBaseUrl}/reportereditor/?is_active=true`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${Config.Token}`,
          },
        }
      );
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching reporter/editor:", error);
    } finally {
      setLoading(false);
    }
  };

  const findUserById = (id) => {
    if (!id || users.length === 0) return null;
    return users.find((user) => user.id === id);
  };

  const selectedUser = findUserById(reporter) || findUserById(editor);

  useEffect(() => {
    if (selectedUser?.email && onUserMail) {
      onUserMail(selectedUser.email);
    }
  }, [selectedUser, onUserMail]);

  if (loading) return null;

  const getClassNames = () => ({
    container: haberDetail
      ? "ozel-haber-detail-badgee-Container"
      : editorDetail
      ? "editor-haber-container"
      : "ozel-haber-container",
    badge: haberDetail ? "ozel-haber-detail-badgee" : "ozel-haber-badgee",
  });

  const { container, badge } = getClassNames();

  const renderBadgeImage = () => {
    if (selectedUser) {
      return (
        <img
          src={selectedUser.image ||kıbrıslıLogo}
          alt={selectedUser.name }
          className={badge}
        />
      );
    }

    // Eğer showDefaultIfEmpty true ise, default image göster
    if (showDefaultIfEmpty) {
      return (
        <img
          src={defaultBadgeImage}
          alt="Özel Haber"
          className={badge}
        />
      );
    }

    return null;
  };

  return <div className={container}>{renderBadgeImage()}</div>;
};

export default OzelHaberEtiketi;
