import React, {useContext, useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {FaFacebook, FaInstagram, FaYoutube} from "react-icons/fa"
import "./Side.css"
import Currentsy from "../Currentsy/Currentsy"
import RelatedComponent from "../../../RelatedComponent/RelatedComponent"
import {RelatedContext} from "../../../../Context/RelatedContext"
import {Config} from "../../../../Config/config"
import SideEncok from "../../../SideEncok/SideEncok"
import defaultImage from "../../../../img/kıbrıslı-gazetesi-haber-default-img-min.jpg"
import { turkceToEnglish } from "../../../PathHelper/PathHelper"
const Side = () => {
  const {Related} = useContext(RelatedContext)
  const [sidebars, setSidebar] = useState([])
  const [specialNews, setSpecialNews] = useState([]) // Özel Haberler için state
  const navigate = useNavigate()

  useEffect(() => {
    // En çok okunan haberleri çek

    // Özel Haberler'i çek
    const fetchSpecialNews = async () => {
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/postbasic/?postcategoryid=12&is_active=true&is_published=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${Config.Token}`,
            },
          }
        )

        if (!response.ok) {
          throw new Error("Özel Haberler alınırken hata oluştu!")
        }

        const data = await response.json()
        setSpecialNews(data.results)
      } catch (error) {
        console.error("API Hatası:", error)
      }
    }

    fetchSpecialNews()
  }, [])

  const handleNewsClick = (id, title) => {
    // const formattedTitle = encodeURIComponent(title.replace(/\s+/g, "-"))
     const formattedTitle = turkceToEnglish(title);
    navigate(`/haber/${id}/${formattedTitle}`)
  }

  return (
    <div className="Sidevar">
      <SideEncok />
      <div className="Related-container-side">
        <div className="Related-containerss">
          <RelatedComponent relatedId={16}  size="sidesize" />
        </div>
      </div>
      {/* Özel Haberler Bölümü */}
      <div className="Table2Wrapper">
        <h2>Özel Haberler</h2>
        <div className="ListWrapper2">
          {specialNews.map((news) => (
            <div key={news.id} className="ListItem2">
              <button onClick={() => handleNewsClick(news.id, news.header)}>
                <img
                  src={
                    news.postimage?.find(
                      (img) => img.is_cover_image
                    )?.uploaded_image?.image || defaultImage
                  }
                  alt={news.header}
                  className="Table2Image"
                />

                <div>
                  <h3>{news.header}</h3>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Sosyal Medya İkonları */}
      <div className="kibris-footer-social-media">
        <p className="kibris-side-social-media-text">
          Sosyal medya hesaplarımızı takip edin!
        </p>
        <div className="kibris-footer-social-media-icons">
          <a
            href="https://www.facebook.com/Kibrisligazetesi/?locale=tr_TR"
            target="_blank"
            rel="noopener noreferrer"
            className="kibris-footer-social-icon"
          >
            <FaFacebook size={30} />
          </a>
          <a
            href="https://www.instagram.com/kibrisligazetesi/"
            target="_blank"
            rel="noopener noreferrer"
            className="kibris-footer-social-icon"
          >
            <FaInstagram size={30} />
          </a>
          <a
            href="https://www.youtube.com/@kibrisliwebtv"
            target="_blank"
            rel="noopener noreferrer"
            className="kibris-footer-social-icon"
          >
            <FaYoutube size={30} />
          </a>
        </div>
              
      </div>
      <div className="Related-container-side">
        <div className="Related-containerss">
          <RelatedComponent relatedId={15}  size="sidesize" />
        </div>
      </div>
      {/* Döviz Kurları */}
      <div className="DovizWrapper">
        <h2>Döviz Kurları</h2>
        <Currentsy />
      </div>
      {/*

    Nöbetçi Eczaneler 
      <div className="DovizWrapper">
        <div
          style={{
            margin: "auto",
            textAlign: "center",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <a href="https://www.eczaneler.gen.tr/nobetci-kibris" target="_blank">
            <img
              src="https://www.eczaneler.gen.tr/resimler/nobetci-eczane.jpg"
              alt="KKTC nöbetçi eczaneleri"
              style={{width: "100%", borderRadius: "2px"}}
            />
          </a>
          <iframe
            src="https://www.eczaneler.gen.tr/iframe.php?lokasyon=82"
            name="KKTC nöbetçi eczaneleri"
            style={{width: "100%", height: "240px", border: "none"}}
          ></iframe>
        </div>
      </div>
      */}
    </div>
  )
}

export default Side
