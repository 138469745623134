import React, {useEffect, useState} from "react"
import "./Wheather.css"

const cities = [
  {
    name: "Baf",
    url: "https://api.weatherapi.com/v1/current.json?key=f47d99766f9741cdab571621250901&q=pafos&lang=tr",
  },
  {
    name: "Dipkarpaz",
    url: "https://api.weatherapi.com/v1/current.json?key=f47d99766f9741cdab571621250901&q=dipkarpaz&lang=tr",
  },
  {
    name: "GaziMağusa",
    url: "https://api.weatherapi.com/v1/current.json?key=f47d99766f9741cdab571621250901&q=magusa&lang=tr",
  },
  {
    name: "Girne",
    url: "https://api.weatherapi.com/v1/current.json?key=f47d99766f9741cdab571621250901&q=girne&lang=tr",
  },
  {
    name: "Güzelyurt",
    url: "https://api.weatherapi.com/v1/current.json?key=f47d99766f9741cdab571621250901&q=guzelyurt&lang=tr",
  },
  {
    name: "İskele",
    url: "https://api.weatherapi.com/v1/current.json?key=f47d99766f9741cdab571621250901&q=iskele&lang=tr",
  },
  {
    name: "Larnaka",
    url: "https://api.weatherapi.com/v1/current.json?key=f47d99766f9741cdab571621250901&q=larnaka&lang=tr",
  },
  {
    name: "Lefke",
    url: "https://api.weatherapi.com/v1/current.json?key=f47d99766f9741cdab571621250901&q=lefke&lang=tr",
  },
  {
    name: "Lefkoşa",
    url: "https://api.weatherapi.com/v1/current.json?key=f47d99766f9741cdab571621250901&q=lefkosia&lang=tr",
  },
  {
    name: "Limasol",
    url: "https://api.weatherapi.com/v1/current.json?key=f47d99766f9741cdab571621250901&q=limasol&lang=tr",
  },
]

const WeatherSlider = () => {
  const [weatherData, setWeatherData] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const fetchWeather = async () => {
      const data = await Promise.all(
        cities.map(async (city) => {
          try {
            const response = await fetch(city.url)
            const result = await response.json()
            return {
              name: city.name,
              temperature: parseInt(result.current.temp_c, 10),
              icon: result.current.condition.icon,
            }
          } catch (error) {
            console.error("Hava durumu verisi alınırken hata oluştu:", error)
            return null
          }
        })
      )
      setWeatherData(data.filter((city) => city !== null))
    }
    fetchWeather()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % weatherData.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [weatherData])

  return (
    <div className="weatherContainer">
      <div className="centered">
        {weatherData.length > 0 && (
          <div className="maincentered">
            <h5 className="color">{weatherData[currentIndex].name}</h5>
            <div className="flex">
              <img
                className="icon"
                src={`https:${weatherData[currentIndex].icon}`}
                alt="Hava durumu ikonu"
              />
              <span className="color">
                {weatherData[currentIndex].temperature}°C
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default WeatherSlider
