import React, {useContext, useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {Config} from "../../Config/config" // Fixed import path with correct case
import "./RelatedComponent.css"
import defalutrelatedImage from "../../img/relatedAlani.png"
import SideDefaultImg from "../../img/435x470.jpg"
import TopAdBiggerImage from "../../img/1200x120.png"
import SliderDefaultImg from "../../img/slider-reklam-alanı.png"
import {RelatedContext} from "../../Context/RelatedContext"

const RelatedComponent = ({relatedId, children, size}) => {
  const navigate = useNavigate()
  const {Related} = useContext(RelatedContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (Related.length >= 0) {
      setIsLoading(false)
    }
  }, [Related])

  if (isLoading) {
    return null
  }

  const Relate = Related.find((Relate) => Relate.advert_area === relatedId)

  const imageToShow = Relate?.image
    ? Relate.image
    : size === "sidesize"
    ? SideDefaultImg
    : size === "topAdBigger"
    ? TopAdBiggerImage
    : size === "SliderDefaultImg"
    ? SliderDefaultImg
    : defalutrelatedImage

  const redirectUrl = Relate?.redirect_url

  const handleClick = async (e) => {
    e.preventDefault() // Prevent default anchor behavior

    try {
      // Check if Relate.id exists before making the fetch request
      if (!Relate?.id) {
        navigate("/reklam")
        return
      }

      // Track the click
      await fetch(
        `https://api.kibrisligazetesi.com/increase-adv-count/${Relate.id}`,
        {
          method: "GET",
          headers: {Authorization: `Token ${Config.Token}`},
        }
      )

      // Handle the navigation
      if (redirectUrl) {
        // If it's an external URL (starts with http or https)
        if (redirectUrl.match(/^https?:\/\//)) {
          window.open(redirectUrl, "_blank")
        } else {
          // For internal routes, remove leading slash if exists and navigate
          const path = redirectUrl.replace(/^\//, "")
          navigate(`/${path}`)
        }
      } else {
        // Default to reklam page if no redirect URL
        navigate("/reklam")
      }
    } catch (error) {
      console.error("Related count alınmıyor:", error)
    }
  }

  return (
    <div className="relatedItem">
      <a
        href={redirectUrl || "#"} // Fallback href
        className="relatedLink"
        onClick={handleClick}
      >
        <img
          src={imageToShow}
          alt={Relate?.header || "Reklam ver"}
          className="relatedImage"
        />
        {children}
      </a>
    </div>
  )
}

export default RelatedComponent
