import React, {useEffect, useState} from "react"
import "./Footer.css"
import Appstore from "../../img/Appstore.png"
import Googleplay from "../../img/Googleplay.png"
import Layer from "../../img/Layer.png"
import {FaFacebook, FaInstagram, FaYoutube} from "react-icons/fa"
import {Config} from "../../Config/config"
import {Link} from "react-router-dom" // Import Link here
import Loading from "../Loading/Loading"
import {turkceToEnglish} from "../PathHelper/PathHelper"
const Footer = () => {
  const [categories, setCategories] = useState([])
  const [isScrolled, setIsScrolled] = useState(false)
  const [error, setError] = useState(null) // Hata durumu ekledim

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/category/?is_show_menu=true&is_active=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${Config.Token}`,
            },
          }
        )
        if (!response.ok) throw new Error("Veri alınamadı")
        const data = await response.json()
        setCategories(data)
      } catch (error) {
        setError(error.message)
      }
    }

    fetchCategories()
  }, [])

  // Sayfa yukarı kaydırma fonksiyonu
  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: "smooth"})
  }

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 200)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <footer className="Footer">
      <div className="footer-top">
        {isScrolled && (
          <div>
            <button
              className="scroll-to-top"
              onClick={scrollToTop}
              aria-label="Scroll to top"
            >
              ↑
            </button>
            <div className="scroll-fixed-socail-icons">
              <a
                href="https://www.facebook.com/Kibrisligazetesi/?locale=tr_TR"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-fixed-social-icon"
              >
                <FaFacebook size={30} />
              </a>
              <a
                href="https://www.instagram.com/kibrisligazetesi/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-fixed-social-icon-claretred"
              >
                <FaInstagram size={30} />
              </a>
              <a
                href="https://www.youtube.com/@kibrisliwebtv"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-fixed-social-icon"
              >
                <FaYoutube size={30} />
              </a>
            </div>
          </div>
        )}
        <div className="footer-center">
          <div className="footer-social-media">
            <p className="social-media-text">Bizi takip edin!</p>
            <div className="footer-social-media-icons">
              <a
                href="https://www.facebook.com/Kibrisligazetesi/?locale=tr_TR"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <FaFacebook size={30} />
              </a>
              <a
                href="https://www.instagram.com/kibrisligazetesi/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <FaInstagram size={30} />
              </a>
              <a
                href="https://www.youtube.com/@kibrisliwebtv"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <FaYoutube size={30} />
              </a>
            </div>
          </div>
          <div className="app-follow">
            <p className="app-text">Haber alanlarımızı takip edin!</p>
            <div className="footer-social-media-icons">
              <a
                href="https://apps.apple.com/tr/app"
                target="_blank"
                rel="noopener noreferrer"
                className="appstore-link"
              >
                <img src={Appstore} alt="App Store" className="appstore-img" />
              </a>
              <a
                href="https://play.google.com/store/apps"
                target="_blank"
                rel="noopener noreferrer"
                className="appstore-link"
              >
                <img
                  src={Googleplay}
                  alt="Google Play"
                  className="appstore-img"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-main">
        <div className="footer-main-center">
          <div className="footer-main-logo">
            {/* Wrap the logo with Link to navigate to the homepage */}
            <Link to="/">
              <img src={Layer} alt="Layer" className="Layer-img" />
            </Link>
            <p className="footer-text">Kıbrıs’ın gündemini anında yakalayın!</p>
          </div>
          <ul className="menu-grid">
            {error ? (
              <li className="menu-item">Hata: {error}</li>
            ) : categories.length > 0 ? (
              categories.map((category, index) => (
                <li key={index} className="menu-item">
                  <Link
                    to={
                      category.name === "Gündem"
                        ? "/"
                        : category.name === "Yazarlar"
                        ? "/yazarlar"
                        : `/${category.id}/${turkceToEnglish(category.name)}`
                    }
                    className="menu-link"
                    state={{categoryName: category.name}}
                  >
                    {/*
                     {category.name}
                    */}
                    {category.name === "Gündem" ? "Ana Sayfa" : category.name}
                  </Link>
                </li>
              ))
            ) : (
              <Loading />
            )}
            {/*
             <li className="menu-item">
              <Link to="/iletisim">İletişim</Link>
            </li>
            <li className="menu-item">
              <Link to="/Related">Related</Link>
            </li>*/}

            <li className="menu-item">
              <Link to="/kunye">Künye</Link>
            </li>
            <li className="menu-item">
              <Link to="/yayin-ilkeleri">Yayın İlkeleri</Link>
            </li>
            <li className="menu-item">
              <Link to="/gizlilik-politikasi">Gizlilik Politikası</Link>
            </li>
            <li className="menu-item">
              <Link to="/cerez">Çerez</Link>
            </li>
            <li className="menu-item">
              <Link to="/iletisim">İletişim</Link>
            </li>
            <li className="menu-item">
              <Link to="/aydinlatma">Müşteri Aydınlatma Metni</Link>
            </li>
            <li className="menu-item">
              <Link to="/ziyaretci-aydinlatma">Ziyaretçi Aydınlatma Metni</Link>
            </li>

            {/*
                 <li className="menu-item">
              <a href="/gizlilik-politikasi">gizlilik-politikasi</a>
            </li>
              <li className="menu-item">
              <a href="/iletisim">iletisim</a>
            </li>
              <li className="menu-item">
              <a href="/yayin-ilkeleri">yayin-ilkeleri</a>
            </li>
            */}
          </ul>
        </div>
      </div>
      <div className="footer-proveredby">
        <div className="footer-proveredby-centered">
          <div className="footer-proveredby-wrapper">
            <a href="https://advertpark.com/" target="_blank">
              <h1>Powered by Advert Park</h1>
            </a>
            <div className="footer-proveredby-wrapper-line"></div>
            <p>
              Kıbrıslı Gazetesi’nde yayımlanan yazı, haber ve fotoğrafların her
              türlü telif hakkı Kıbrıslı Gazetesi’ne aittir. İzin alınmadan,
              kaynak gösterilmeden ve link verilmeden iktibas edilemez.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
