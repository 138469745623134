import React, {useContext} from "react"
import RelatedComponent from "./RelatedComponent"
import {RelatedContext} from "../../Context/RelatedContext"
import "./TopRelatedCategory.css" // Import the CSS file

export default function TopRelatedCategory() {
  const {Related} = useContext(RelatedContext)

  const addCategory = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 12vw",
  }

  return (
    <div style={addCategory} className="addCategory">
      <div className="Related-containerss">
        <div className="Related-containerss-bottom-row">
          <RelatedComponent relatedId={29}  size="topAdBigger" />
        </div>
        {/*
          <div className="Related-containerss-row">
          <RelatedComponent relatedId={2}  />
          <RelatedComponent relatedId={3}  />
        </div>
        */}
      </div>
    </div>
  )
}
