import React, {useState, useEffect} from "react"
import "./Popup.css"
import RelatedComponent from "../RelatedComponent"

const Popup = ({Related}) => {
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('popup-open')
    } else {
      document.body.classList.remove('popup-open')
    }
  }, [isOpen])

  const handleClose = (e) => {
    e.stopPropagation()
    setIsOpen(false)
  }

  return (
    isOpen && (
      <div className="popup-overlay" onClick={handleClose}>
        <div className="popup-content" onClick={e => e.stopPropagation()}>
          <div className="close-button-container">
            <button className="close-button" onClick={handleClose}>
              ✖
            </button>
          </div>
          <RelatedComponent relatedId={1}  size="sidesize" />
        </div>
      </div>
    )
  )
}

export default Popup
