import React, {useContext, useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import "./SideEncok.css"
import {Config} from "../../Config/config"
import {RelatedContext} from "../../Context/RelatedContext"
import defaultImage from "../../img/kıbrıslı-gazetesi-haber-default-img-min.jpg"
import { turkceToEnglish } from "../PathHelper/PathHelper"
const SideEncok = () => {
  const {Related} = useContext(RelatedContext)
  const [sidebars, setSidebar] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    // En çok okunan haberleri çek
    const fetchMostRead = async () => {
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/postbasic/?is_most_read=true&is_active=true&is_published=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${Config.Token}`,
            },
          }
        )

        if (!response.ok) {
          throw new Error("Veri alınırken hata oluştu!")
        }

        const data = await response.json()
        setSidebar(data.results)
      } catch (error) {
        console.error("API Hatası:", error)
      }
    }

    fetchMostRead()
  }, [])

  const handleNewsClick = (id, title) => {
    // const formattedTitle = encodeURIComponent(title.replace(/\s+/g, "-"))
     const formattedTitle = turkceToEnglish(title);
    navigate(`/haber/${id}/${formattedTitle}`)
  }

  return (
    <div className="Side-Encokvar">
      {/* En Çok Okunanlar Bölümü */}
      <div className="Side-EncokTable1Wrapper">
        <h2>En Çok Okunanlar</h2>
        <ul className="Side-EncokScrollableList1">
          {sidebars.map((sidebar) => (
            <li key={sidebar.id} className="Side-EncokListItem2">
              <button
                onClick={() => handleNewsClick(sidebar.id, sidebar.header)}
                className="Side-EncokButton"
              >
                <img
                  src={
                    sidebar.postimage?.find(
                      (img) => img.is_cover_image
                    )?.uploaded_image?.image || defaultImage
                  }
                  alt={sidebar.header}
                  className="Table2Image"
                />

                <div className="Side-EncokContent">
                  <h3>{sidebar.header}</h3>
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default SideEncok
