import React, {useContext} from "react"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import TitleBox from "../../Components/TitleBox/TitleBox"
import RelatedComponent from "../../Components/RelatedComponent/RelatedComponent"
import {RelatedContext} from "../../Context/RelatedContext"
import "./Gizlilik_politikasi.css"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent"
export default function Gizlilik_politikasi() {
  const {Related} = useContext(RelatedContext)

  function reloadCSS() {
    let links = document.querySelectorAll('link[rel="stylesheet"]')
    links.forEach((link) => {
      let href = link.getAttribute("href")
      link.setAttribute(
        "href",
        href.split("?")[0] + "?v=" + new Date().getTime()
      )
    })
  }

  // CSS'yi güncellemek için çağırabilirsiniz
  reloadCSS()

  return (
    <>
      <TopRelatedCategory />
      <div className="Cache-Control-gizlilik-aydinlatma-body">
        <div className="Cache-Control-gizlilik-aydinlatma-container">
          <div className="Cache-Control-gizlilik-aydinlatma-content">
            <TitleBox title={"Gizlilik Politikası"} />
            <div className="Cache-Control-gizlilik-aydinlatma-left-line" />
            <h1 className="Cache-Control-gizlilik-aydinlatma-Title">
              Gizlilik Politikası
            </h1>
            <div>
              <h3 className="Cache-Control-gizlilik-aydinlatma-titleh3">
                Kıbrıslı Gazetesi A.Ş. Gizlilik Politikası
              </h3>
              <p>
                Kıbrıslı Gazetesi A.Ş. (Şirket) olarak mahremiyetinize ve
                kişisel verilerinizin güvenliğine önem vermekteyiz. Kişisel
                verilerinizin ve mahremiyetinizin korunması bizim için sadece
                kanuni bir yükümlülüğün yerine getirilmesinden ibaret değil, iş
                modelimizle güvenle ve gönül rahatlığıyla tasarrufta bulunmanız
                için siz müşterilerimiz ve iş ortaklarımız için temel bir
                taahhüdümüzdür.
              </p>

              <p>
                İşbu Gizlilik Politikamızın amacı, 6698 sayılı Kişisel Verilerin
                Korunması Kanunu (Kanun) uyarınca temas ettiğimiz kişisel
                veriler veya kişisel olmaya verilere ilişkin, Şirketimizin genel
                anlayışını ve ruhunu sizlerle paylaşmak; bu kapsamda değerli
                ziyaretçilerimizi bilgilendirmektir. Bu kapsamda Şirket,
                Kanun’da öngörülen ilkeler kapsamında, kişisel verilerin
                işlenmesi, silinmesi, yok edilmesi, anonim hale getirilmesi,
                aktarılması, ilgili kişinin aydınlatılması ve veri güvenliğinin
                sağlanmasıyla ilgili Kanun’dan kaynaklı yükümlülüklerini yerine
                getirmektedir. İşbu Gizlilik Politikası sizlere kişisel
                verilerin işlenmesi süreçlerine ilişkin bilgilendirmeler
                içermektedir.
              </p>

              <p>
                Şirket site sayfalarını, şahsi herhangi bir bilgi vermeden
                ziyaret edebilir, ürünlerimiz ve servislerimiz hakkında bilgi
                alabilir, haberlerimizi okuyabilir, insan kaynakları ve diğer
                detaylı hizmetlerimizden yararlanabilirsiniz. Size özel
                bilgilerinizi vermeniz durumunda, bu bilgiler, sizin haberiniz
                veya onayınız olmadan ya da yasal yükümlülük altında bulunmadığı
                sürece üçüncü bir şahıs veya firma ile paylaşılmayacaktır. Bu
                bilgiler, en yüksek güvenlik ve gizlilik standartlarımızla
                korunacaktır. Bu bilgilerinizin işlenmesi ve kullanımı ile
                ilgili olarak Aydınlatma Metnimizi okuyabilirsiniz.
              </p>
              <p>
                Sitemizin kullanımı sırasında oluşabilecek herhangi bir hata,
                kesinti, bilgi naklinde gecikme, bilgisayar virüsü, hat ve/veya
                sistem arızası sonucunda ortaya çıkabilecek doğrudan veya
                dolaylı zarar ve masraflardan Şirketimiz sorumlu değildir.
              </p>

              <p>
                Şirketimiz üçüncü şahıslar tarafından idare edilen internet
                sitelerine bağlantılar ve yönlendiriciler sağlamaktadır.
                Şirketimiz, bu sitelerin içeriklerini denetlememekte ve bu
                sitelerin kullanımından doğabilecek her türlü zararlara karşı
                Şirketimiz tarafından hiçbir garanti verilmemektedir.
              </p>

              <p>
                Sitemizde yer alan bilgi, materyal ve bunların düzenlenmesi
                konusundaki telif hakları, Şirketimize aittir. Sitemizin
                içerdiği bilgi ve materyallere dair tüm telif hakları, tescilli
                marka, patent, entelektüel ve diğer mülkiyet hakları
                Şirketimizde saklıdır. Herhangi bir kişi/kuruluş, önceden
                Şirketimizin yazılı iznini almadıkça, sitemizin belli bir
                kısmını başka bir web sitesinde kullanamaz veya sitemize başka
                bir web sitesinin bağlantısını kuramaz.
              </p>

              <p>
                Bu kişisel veri işleme faaliyetlerinden, halka açık olabilecek
                kapsam ve sınırlardaki kişisel veri işleme faaliyetlerine
                ilişkin hukuki sebep, toplanan veri kategorileri ve diğer işleme
                faaliyetlerine ilişkin detayları ilgili aydınlatma metninde
                sizlerle paylaşmaktayız.
              </p>

              <p>
                <strong>
                  www.kibrisligazetesi.com internet sitesinde yayınlanan yazı,
                  haber ve fotoğrafların her türlü telif hakkı KIBRISLI HABER
                  AJANSI BASIN VE YAYINCILIK A.Ş.’ye aittir. İzin alınmadan,
                  kaynak gösterilerek dahi iktibas edilemez.
                </strong>
                 
              </p>
              <h3 className="Cache-Control-gizlilik-aydinlatma-titleh3">
                Kişisel Veri Toplama Yöntemleri ve Hukuki Sebepleri
              </h3>
              <p>
                Şirket, www.kibrisligazetesi.com başta olmak üzere diğer
                internet siteleri ve mobil aplikasyonlar, sosyal medya
                hesapları, fiziki mekânlarının ziyaretleri, çağrı merkezleri,
                şirket e-posta adreslerine gönderilen iletiler, çerezler, faks,
                posta, CCTV, adli ve idari makamlardan kendisine gönderilen
                tebligatlar ve sair iletişim kanalları aracılığıyla işitsel,
                elektronik veya yazılı olarak, Kanun’da belirtilen kişisel veri
                işleme şartlarına uygun olarak toplamaktadır.
              </p>
              <p>
                Kişisel verileriniz, Şirket ile aranızdaki hukuki ilişkinin
                niteliğine göre (örneğin, müşteri, müşteri adayı, çalışan,
                çalışan adayı, fiziksel ziyaretçi, çevrimiçi ziyaretçi, iş çözüm
                ortağı / tedarikçi) farklı hukuki sebeplerle işlenmektedir.
                Keza, sürecin niteliğine göre de (örneğin, çağrı merkezi veya
                etkinlik süreçlerinde) farklı nitelikte kişisel veriyi
                toplamaktayız.
              </p>
              <h3 className="Cache-Control-gizlilik-aydinlatma-titleh3">
                Kişisel Verilerin Hangi Amaçlarla İşlendiği
              </h3>
              <p>
                Şirket olarak, tüm süreçlerimizde faaliyetlerin mevzuata uygun
                yürütülmesini temin etmek ve sizlere en iyi hizmeti vermeyi
                amaçlamaktayız. Her bir aydınlatma metninde, kişisel verilerin
                hangi amaçlarla işlendiği detaylı olarak yer almaktadır.
              </p>
              <h3 className="Cache-Control-gizlilik-aydinlatma-titleh3">
                Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği
              </h3>
              <p>
                Şirket, kişisel verilerinizi ölçülülük ve gereklilik ilkelerine
                uygun olarak ve gerekli teknik ve idari güvenlik tedbirlerini
                alarak işlemeye özen göstermektedir. Lakin, iş faaliyetlerinin
                yürütülmesi veya denetimi, iş sürekliliğinin sağlanması, dijital
                altyapıların işletilmesi farklı paydaşlarla sürekli veri akışını
                zaruri kıldığı için işlediğimiz kişisel verileri belirli
                amaçlarla üçüncü kişilerle aktarıyoruz. Ayrıca, sözleşme ve
                kanuni yükümlülüklerini tam ve gereği gibi yerine getirebilmesi
                için kişisel verilerinizin doğru ve güncel olması çok önemlidir.
                Bunun için de muhtelif iş ortaklarıyla ve hizmet sağlayıcılarla
                çalışmak durumundayız. Her koşulda kişisel veri aktarımları,
                güvenli ortam ve kanallar aracılığıyla gerçekleştirilmektedir.
              </p>
              <p>
                Kişisel verileriniz, işleme amacının gerektirdiği durumlarda ve
                sadece bununla sınırlı olarak şirket içi aktarılmakta, gerçek
                kişiler veya özel hukuk tüzel kişilerle, iş ortaklarıyla,
                iştirakler ve bağlı ortaklıklarla, tedarikçiler yetkili kamu
                kurum ve kuruluşlarıyla Kanun’da belirtilen kurallar
                çerçevesinde paylaşılmaktadır. Her bir aydınlatma metninde,
                kişisel verilerin hangi amaçlarla işlendiği ve hangi amaçlarla
                üçüncü kişilere aktarıldığı detaylı olarak yer almaktadır.
              </p>
              <h3 className="Cache-Control-gizlilik-aydinlatma-titleh3">
                Kişisel Verilerin Güvenliğini Sağlamak Amacıyla Alınan Teknik ve
                İdari Tedbirler
              </h3>
              <p>
                Şirket, kişisel verilerinizin gizliliği, bütünlüğü ve
                güvenliğinin sağlanması için gerekli teknik ve idari her türlü
                tedbiri almayı ve gerekli özeni göstermeyi taahhüt etmektedir.
                Bu kapsamda, kişisel verilerin hatalı kullanımını, hukuka aykırı
                olarak işlenmesini, verilere yetkisiz erişimi, verilerin ifşa
                edilmesini, değiştirilmesini veya imha edilmesini engellemek
                için gerekli önlemleri almaktayız.
              </p>
              <p>
                Şirket, işlediği kişisel verilere hukuka aykırı erişimin
                engellenmesi, bu verilerin hukuka aykırı işlenmesinin önlenmesi
                ve kişisel verilerin muhafazasının sağlanmasına ilişkin olarak
                aldığımız teknik ve idari tedbirleri VERBİS’te (Veri Sorumluları
                Sicil Bilgi Sistemi) görebilirsiniz.
              </p>
              <p>
                Şirket’in gerekli bilgi güvenliği önlemlerini almasına karşın,
                Şirket tarafından işletilen platformlara veya Şirket sistemine
                yapılan saldırılar sonucunda kişisel verilerin zarar görmesi
                veya yetkisiz üçüncü kişilerin eline geçmesi durumunda, Şirket
                bu durumu derhal sizlere ve Kişisel Verileri Koruma Kurulu’na
                bildirir ve gerekli önlemleri alır.
              </p>
              <h3 className="Cache-Control-gizlilik-aydinlatma-titleh3">
                Kişisel Verilerin Saklanması ve Kişisel Verilerin Silinmesi, Yok
                Edilmesi ve Anonimleştirilmesi Şartları
              </h3>

              <p>
                Şirket, işlediği kişisel verileri ilgili mevzuatta öngörülen
                veya işleme amacının gerektirdiği süreler boyunca Kanun ile
                uyumlu olarak muhafaza eder. Kişisel Verilerin Saklanması ve
                İmhası Politikasında ve Kişisel Veri Envanterinde bu süreler
                detaylı olarak belirlenmiştir.
              </p>

              <p>
                Şirket, iş süreçleri kapsamında fiziki, elektronik, web sitesi,
                e-posta gibi kanallardan toplayarak işlediği kişisel verileri,
                Kanun madde 7, 17 ve Türk Ceza Kanunu Madde 138 uyarınca ilgili
                kanunların öngördüğü süreler ve/veya işleme amacının gerekli
                kıldığı süreler boyunca saklamaktadır. Bu sürelerin sona ermesi
                durumunda ise Kişisel Verilerin Silinmesi, Yok Edilmesi veya
                Anonim Hale Getirilmesi Hakkında Yönetmelik hükümleri ve sair
                mevzuat uyarınca silecek, yok edecek veya anonim hale
                getirecektir.
              </p>
              <h3 className="Cache-Control-gizlilik-aydinlatma-titleh3">
                İlgili Kişinin Hakları
              </h3>

              <p>
                İlgili Kişinin Kanun madde 11 uyarınca sahip olduğu haklar
                aşağıda belirtilmiştir:
              </p>
              <p>
                <ul>
                  <li>Kişisel veri işlenip işlenmediğini öğrenme</li>
                  <li>
                    Kişisel verileri işlenmişse buna ilişkin bilgi talep etme
                  </li>
                  <li>
                    Kişisel verilerin işlenme amacını ve bunların amacına uygun
                    kullanılıp kullanılmadığını öğrenme
                  </li>
                  <li>
                    Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                    üçüncü kişileri bilme
                  </li>
                  <li>
                    Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                    bunların düzeltilmesini isteme
                  </li>
                  <li>
                    KVK Kanunu 7. maddede öngörülen şartlar çerçevesinde kişisel
                    verilerin silinmesini veya yok edilmesini isteme
                  </li>
                  <li>
                    (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel
                    verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme
                  </li>
                  <li>
                    İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                    analiz edilmesi suretiyle kişinin kendisi aleyhine bir
                    sonucun ortaya çıkmasına itiraz etme
                  </li>
                  <li>
                    Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                    zarara uğraması hâlinde zararın giderilmesini talep etme
                  </li>
                </ul>
              </p>

              <p>
                Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi
                belirtmek ve kişisel verileriniz üzerindeki haklarınızı
                kullanmak amacıyla, “İlgili Kişi Başvuru Formu”nu doldurarak,
                şirketin resmi e-mail adresi
                (kvkk@cumhuriyet.com.tr veya yenigun@hs01.kep.tr ) üzerinden
                güvenli elektronik imza ile veya kişisel e-posta adresiniz
                üzerinden veya Şirketin Prof. Nurettin Mazhar Öktel Sk. No:2
                Şişli/İstanbul adresine şahsen veya noter vasıtasıyla yazılı
                başvurarak gerekli değişiklik, güncelleme ve/veya silme gibi
                işlemleri ve ilgili talepleri gerçekleştirebilirsiniz.
                Belirtilen yöntemlerle taleplerinizi bize iletmeniz durumunda
                Şirket, talebinizin niteliğine göre talebi en kısa sürede ve en
                geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak,
                işlemin ayrıca bir maliyeti gerektirmesi hâlinde, Şirket
                tarafından Kişisel Verileri Koruma Kurulu’nca belirlenen
                tarifedeki ücret alınacaktır.
              </p>
              <p>
                Kişisel veri sahibi olarak sahip olduğunuz ve yukarıda
                belirtilen haklarınızı kullanmak için yapacağınız hakka ilişkin
                açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun açık
                ve anlaşılır olması, talep ettiğiniz konunun şahsınız ile ilgili
                olması veya başkası adına hareket ediyor iseniz bu konuda özel
                olarak yetkili olmanız ve yetkinizi belgelendirilmesi,
                başvurunun kimlik ve adres bilgilerini içermesi ve başvuruya
                kimliğinizi tevsik edici belgelerin eklenmesi gerekmektedir.
              </p>
            </div>
            <SingleRelatedComponent />
          </div>
          <div className="Cache-Control-gizlilik-aydinlatma-side">
            <Side />
          </div>
        </div>
      </div>
    </>
  )
}
