import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Side from "../../Components/Navbar/PlayersList/Side/Side";
import"./Muhabirler.css"
import { Config } from "../../Config/config";
import TitleBox from "../../Components/TitleBox/TitleBox";
import { RelatedContext } from "../../Context/RelatedContext";
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory";
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent";
import Loading from "../../Components/Loading/Loading";
const Yazarlarimiz = () => {
  const [yazarlar, setYazarlar] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { Related } = useContext(RelatedContext);
  useEffect(() => {
    const fetchYazarDetails = async () => {
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/reportereditor/?is_reporter=true&is_active=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${Config.Token}`,
            },
          }
        );
        const data = await response.json();
        setYazarlar(data);
      } catch (error) {
        console.error("Hata:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchYazarDetails();
  }, []);

  if (loading) {
    return <Loading />;
  }

  const handleRedirect = (name) => {
    navigate(`/yazarlar/${name}`);
  };
  return (
    <>
      <TopRelatedCategory />
      <div className="Muhabirler-page">
        <TitleBox title="Muhabirler" />
        <div className="Muhabirler-container">
          <div className="Muhabirler-list">
            <h1>Yazarlarımız</h1>
            <div className="Muhabirler-cell">
              {yazarlar.map((yazar) => (
                <div key={yazar.id} className="Muhabirler">
                  <div className="Muhabirler-image-container">
                    <img
                      src={yazar.image || "default-image.jpg"}
                      alt={yazar.name}
                      className="Muhabirler-image"
                    />
                  </div>

                  <h2 className="red-header">{yazar.name}</h2>
                  <p>{yazar.title || "Unvan: Bilinmiyor"}</p>
                  <p>{yazar.email}</p>
                  <div className="Muhabirler-buttons-wrappers">
                    <button
                      className="btn-blue"
                      onClick={() => handleRedirect(yazar.name)}
                    >
                      Tüm Yazıları
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <SingleRelatedComponent />
          </div>

          <div className="Muhabirler-side">
            <Side />
          </div>
        </div>
      </div>
    </>
  );
};

export default Yazarlarimiz;
