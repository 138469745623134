import React, {useState, useContext, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import "./CategorySlider.css"
import RelatedComponent from "../../../Components/RelatedComponent/RelatedComponent"
import {RelatedContext} from "../../../Context/RelatedContext"
import HaberDeafultImage from "../../../img/kıbrıslı-gazetesi-haber-default-img-min.jpg"
import OzelHaberImage from "../../../Components/OzelHaberEtiketi/OzelHaberImage"
import OzelHaberEtiketi from "../../../Components/OzelHaberEtiketi/OzelHaberEtiketi"
const CategorySlider = ({articles}) => {
  const {Related} = useContext(RelatedContext)
  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [showAd, setShowAd] = useState(false)
  const [relatedId, setrelatedId] = useState(2)

  const handleNewsClick = (id, title) => {
    const formattedTitle = encodeURIComponent(title.replace(/\s+/g, "-"))
    navigate(`/haber/${id}/${formattedTitle}`)
  }

  const handleButtonClick = (index, isR = false) => {
    setCurrentIndex(index)
    if (isR) {
      setrelatedId(index === 2 ? 2 : 3)
    }
    setShowAd(isR)
  }

  const handleButtonHover = (index, isR = false) => {
    setCurrentIndex(index)
    if (isR) {
      setrelatedId(index === 2 ? 3 : 2)
    }
    setShowAd(isR)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % articles.length)
    }, 5000)

    return () => clearInterval(interval)
  }, [articles.length]) // sliderItems.length yerine articles.length

  const renderArticles = articles.map((article, index) => {
    const coverImage = article.postimage?.find((img) => img.is_cover_image)
      ?.uploaded_image?.image

   const ReporterImg=article.reporter__image
    return (
      <div
        key={article.id}
        className="special-id-category-slider-item"
        onClick={() => handleNewsClick(article.id, article.header)}
        style={{
          display: index === currentIndex ? "block" : "none",
        }}
      >
        <div className="special-id-category-slider-image-container">
        <OzelHaberImage  ReporterImg={ReporterImg} categorySlider/> 
        {article.postcategory?.find(categoryItem => categoryItem.category === 12) && !ReporterImg &&(
                    <OzelHaberEtiketi showDefaultIfEmpty={true} haberDetail={true} />
                   )}
          {coverImage ? (
            <img
              src={coverImage}
              alt={article.header}
              className="special-id-category-slider-image"
            />
          ) : (
            <img
              src={HaberDeafultImage}
              alt=""
              className="category-slider-default-img"
            />
          )}
          <div className="special-id-category-slider-overlay">
            <h3 className="special-id-category-slider-title">
              <div className="special-id-category-slider-overlay-test">
                {article.header}
              </div>
            </h3>
          </div>
        </div>
      </div>
    )
  })

  const renderPageNumbers = articles.slice(0, 4).map((_, index) => {
    const showR = index === 2 || index === 3

    return (
      <React.Fragment key={index}>
        <button
          className={`special-id-category-page-btn ${
            index === currentIndex && !showAd ? "active" : ""
          }`}
          onClick={() => handleButtonClick(index)}
          onMouseEnter={() => handleButtonHover(index)}
        >
          {index + 1}
        </button>
        {showR && (
          <button
            className="special-id-category-r-btn"
            onClick={() => handleButtonClick(index, true)}
            onMouseEnter={() => handleButtonHover(index, true)}
          >
            R
          </button>
        )}
      </React.Fragment>
    )
  })

  return (
    <>
      <div className="special-id-category-slider-container">
        <div className="special-id-category-slider">
          <div className="special-id-category-slider-wrapper">
            {showAd ? (
              // R butonuna basılmışsa RelatedComponent göster
              <div className="special-id-category-slider-item">
                <RelatedComponent  relatedId={relatedId} size="SliderDefaultImg" />
              </div>
            ) : (
              // Normal haberleri göster
              renderArticles
            )}
          </div>
        </div>
      </div>

      <div className="renderPageNumbers">{renderPageNumbers}</div>
    </>
  )
}

export default CategorySlider
