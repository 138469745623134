import React, {useState} from "react"
import "./Iletisim.css"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import TitleBox from "../../Components/TitleBox/TitleBox"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent"

const Iletisim = () => {
  const [selectedOption, setSelectedOption] = useState("")

  const handleChange = (event) => {
    setSelectedOption(event.target.value)
  }

  return (
    <>
      <TopRelatedCategory />
      <div className="iletisim-titleBox">
        <TitleBox title={"Iletisim"} />
      </div>

      <div className="iletisim">
        <div className="iletisim-ad">
          <div className="iletisim-articles">
            <div className="iletisim-articles-kunye">
              {/* <div className="iletisim-articles-kunye-form">
                <div className="iletisim-form">
                  <div className="iletisim-form-container">
                    <input placeholder="Ad Soyad*" className="iletisim-input" />
                    <input placeholder="Telefon*" className="iletisim-input" />
                    <input placeholder="E-Posta*" className="iletisim-input" />
                    <div className="iletisim-form-dropdown">
                      <select
                        id="dropdown"
                        value={selectedOption}
                        className="iletisim-input"
                        onChange={handleChange}
                      >
                        <option value="bana">Kime*</option>
                        <option value="anasayfa">Anasayfa</option>
                        <option value="hakkimizda">Hakkımızda</option>
                        <option value="iletisim">İletişim</option>
                      </select>

                      <select
                        id="dropdown"
                        value={selectedOption}
                        className="iletisim-input"
                        onChange={handleChange}
                      >
                        <option value="bana">Konu*</option>
                        <option value="anasayfa">Anasayfa</option>
                        <option value="hakkimizda">Hakkımızda</option>
                        <option value="iletisim">İletişim</option>
                      </select>
                    </div>
                    <input
                      id="special-input-with-res"
                      placeholder="Mesaj*"
                      className="iletisim-mesaj-input"
                    />
                    <div className="iletisim-form-dropdown">
                      <input
                        placeholder="Doğrulama Kodu*"
                        className="iletisim-input"
                      />
                      <input
                        id="special-input-with-res"
                        placeholder="G8t*Zx7*"
                        className="iletisim-input"
                      />
                    </div>
                    <button className="iletisim-send-button">Gönder</button>
                  </div>
                </div>
              </div> */}
              <div className="iletisim-articles-kunye-details">
                <div className="iletisim-bilgileri-container">
                  <div className="iletisim-bilgileri-section">
                    <h2 className="iletisim-bilgileri-bold-font">Adres:</h2>
                    <a
                      href="https://www.google.com/maps?q=Yüksel+Altan+Apartmanı,+Mete+Adanır+Cd.+Daire+2,+Girne+0392+KKTC"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Yüksel Altan Apartmanı, Mete Adanır Cd. Daire 2, Girne
                      0392 KKTC
                    </a>
                  </div>
                  <div className="iletisim-bilgileri-section">
                    <h2 className="iletisim-bilgileri-bold-font">Telefon:</h2>
                    <a href="tel:+905338880777">+90 (533) 888 07 77</a>
                  </div>
                  <div className="iletisim-bilgileri-section">
                    <h2 className="iletisim-bilgileri-bold-font">Mail:</h2>
                    <a href="mailto:haber@kibrisligazetesi.com">
                      haber@kibrisligazetesi.com
                    </a>
                  </div>

                  <div className="iletisim-bilgileri-section">
                    <h2 className="iletisim-bilgileri-bold-font">
                      Related Telefon:
                    </h2>
                    <a href="tel:+905338881137">+90 (533) 888 11 37</a>
                  </div>

                  <div className="iletisim-bilgileri-section">
                    <h2 className="iletisim-bilgileri-bold-font">
                      Related Mail:
                    </h2>
                    <a href="mailto:Related@kibrisligazetesi.com">
                      Related@kibrisligazetesi.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="iletisim-articles-maps">
              {/* Google Maps Embed */}
              <div className="iletisim-articles-maps">
                <div className="iletisim-articles-maps">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.138679299754!2d33.312015!3d35.333862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de137e0521c83d%3A0x4afd8b52ed45ed5!2sK%C4%B1br%C4%B1sl%C4%B1%20Gazetesi!5e0!3m2!1str!2str!4v1710000000000"
                    width="100%"
                    height="300"
                    style={{border: 0}}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="iletisim-single-ad-component">
              <SingleRelatedComponent />
            </div>
          </div>
        </div>

        <div className="iletisim-details-side-wrapper">
          <Side />
        </div>
      </div>
    </>
  )
}

export default Iletisim
